import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, reactive, ref, toRaw, computed, onMounted } from 'vue';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import getFieldRules from '@/utils/fieldRules';
import { CommentOutlined, UserOutlined, SmileOutlined } from '@ant-design/icons-vue';
import useTablePagination from '@/hooks/pagination';
import { useStore } from 'vuex';
import { insertKnFeedback, selectKnFeedbackByPagination, updateFeedback } from '@/api/help';
//表头
const columns = [{
  title: '反馈内容',
  dataIndex: 'content'
}, {
  title: '反馈时间',
  dataIndex: 'feedbackTime'
}, {
  title: '联系电话',
  dataIndex: 'mobile'
}, {
  title: '联系邮箱',
  dataIndex: 'email'
}, {
  title: '状态',
  dataIndex: 'state',
  slots: {
    customRender: 'state'
  }
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  }
}];
export default defineComponent({
  components: {
    uploadImgs,
    CommentOutlined,
    UserOutlined,
    SmileOutlined
  },

  setup() {
    const formRef = ref();
    const formState = reactive({
      desc: '',
      fileUrl: [],
      mobile: undefined,
      email: undefined
    });
    const {
      checkPhone,
      email
    } = getFieldRules();
    const rules = {
      desc: [{
        required: true,
        message: '反馈内容不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      mobile: [{
        required: true,
        validator: checkPhone,
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      email: [{
        required: true,
        validator: email,
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }]
    };

    const onSubmit = () => {
      formRef.value.validate().then(async () => {
        let obj = {
          content: formState.desc ? formState.desc : null,
          fileUrl: JSON.stringify(formState.fileUrl),
          phone: formState.mobile ? formState.mobile : null,
          email: formState.email ? formState.email : null
        };
        loading.value = true;
        let res = await insertKnFeedback(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          loading.value = false;
        } else {
          _message.error(res.message);

          loading.value = false;
        }
      }).catch(error => {
        loading.value = false;
        console.log('error', error);
      });
    }; //获取附件的url


    const getAdjuncts = val => {
      formState.fileUrl = val;
    }; //在线客服


    const loading = ref(false);
    const visible = ref(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = () => {
      loading.value = true;
      setTimeout(() => {
        loading.value = false;
        visible.value = false;
      }, 2000);
    };

    const handleCancel = () => {
      visible.value = false;
    };

    const sendText = ref("");
    const dialogueList = ref([{
      type: 'left',
      text: '12222ssscccc'
    }, {
      type: 'left',
      text: '12222ssscccc'
    }, {
      type: 'right',
      text: '12222ssscccc'
    }, {
      type: 'left',
      text: '12222ssscccc'
    }]);

    const send = () => {
      dialogueList.value.push({
        type: 'right',
        text: sendText.value
      });
      console.log(sendText.value);
      sendText.value = "";
    };

    const personId = ref(['1111111', 'wwwwww', '333333333333']);
    const selectedKeys = ref([]); //管理员

    const formRef1 = ref();
    const formState1 = reactive({
      date: [],
      state: undefined
    }); //点击查询

    const query = () => {
      current.value = 1;
      getTableData();
    }; //查询条件重置


    const resetForm = () => {
      formRef1.value.resetFields();
      current.value = 1;
      getTableData();
    }; //表格


    const tableData = ref([]); //分页信息

    const {
      paginationConfig,
      total,
      current,
      pageSize
    } = useTablePagination(); //点击分页

    const onPageChange = pagination => {
      current.value = pagination.current;
      getTableData();
    };

    const getTableData = async () => {
      const {
        date,
        state
      } = formState1;
      const [startTime, endTime] = date.map((item, index) => {
        if (index) {
          return item.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        }

        return item.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      });
      const param = {
        pageNumber: current.value,
        limit: pageSize.value ? pageSize.value : 10,
        state: state !== undefined ? state : null,
        startTime: startTime ? startTime : null,
        endTime: endTime ? endTime : null
      };
      loading.value = true;
      let res = await selectKnFeedbackByPagination(param);

      if (res.code === 200) {
        const arr = [];
        tableData.value = [];
        if (res.data.list) res.data.list.forEach(item => {
          arr.push({
            key: item.id,
            uid: item.uid,
            content: item.content,
            feedbackTime: item.createTime.split(' ')[0],
            mobile: item.phone,
            state: item.state,
            email: item.email,
            fileUrl: item.fileUrl
          });
        });
        Object.assign(tableData.value, arr);
        total.value = res.data.countSize;
        current.value = res.data.nowPage;
        pageSize.value = res.data.pageSize;
        loading.value = false;
      } else {
        loading.value = false;

        _message.error(res.message);
      }
    }; //详情


    const toView = item => {
      console.log(item);
      feedbackVisible.value = true;
      Object.assign(feedbackDetail, item);
      feedbackDetail.fileUrl = JSON.parse(feedbackDetail.fileUrl);
      console.log(feedbackDetail);
    };

    const feedbackVisible = ref(false);
    const feedbackDetail = reactive({
      content: "",
      feedbackTime: "",
      mobile: "",
      email: "",
      state: "",
      fileUrl: []
    });

    const handleOpinion = async id => {
      const param = {
        id: id,
        state: 1
      };
      let res = await updateFeedback(param);

      if (res.code === 200) {
        _message.success(res.message);

        feedbackVisible.value = false;
        getTableData();
      } else {
        _message.error(res.message);
      }
    };

    const store = useStore();
    const isCompanyAdmin = computed(() => store.state.user.isCompanyAdmin);
    onMounted(() => {
      if (isCompanyAdmin.value == 1) {
        getTableData();
      }
    });
    return {
      formRef,
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      other: '',
      formState,
      rules,
      onSubmit,
      getAdjuncts,
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      dialogueList,
      sendText,
      send,
      selectedKeys,
      personId,
      query,
      resetForm,
      formState1,
      formRef1,
      columns,
      paginationConfig,
      tableData,
      loading,
      onPageChange,
      toView,
      feedbackVisible,
      feedbackDetail,
      handleOpinion,
      isCompanyAdmin
    };
  }

});