/*
 * 帮助相关接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
//帮助文档路径申请接口
export const uploadCloudHelpFileUrl = (param) => {
  return axios.post(`/personnelapi/HyVisitors/uploadCloudHelpFileUrl`, param);
}
//获取帮助文档路径申请接口
export const selectCloudHelpFileUrl = (param) => {
  return axios.post(`/personnelapi/HyVisitors/selectCloudHelpFileUrl`, param);
}
//添加反馈
export const insertKnFeedback = (param) => {
  return axios.post(`/personnelapi/knFeedback/insertKnFeedback`, param);
}
//查询反馈
export const selectKnFeedbackByPagination = (param) => {
  return axios.post(`/personnelapi/knFeedback/selectKnFeedbackByPagination`, param);
}
//修改反馈状态
export const updateFeedback = (param) => {
  return axios.post(`/personnelapi/knFeedback/updateFeedback`, param);
}